<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        <stemble-latex content="$50.00\,\text{mL}$" />
        of an unknown solution containing
        <chemical-latex content="Ce^{4+}" />
        was pipetted into a
        <stemble-latex content="$500\,\text{mL}$" />
        volumetric flask and the solution was diluted to the mark with deionized water.
        <stemble-latex content="$20.00\,\text{mL}$" />
        of the dilute solution was the pipetted into a column packed with a sulfonated polystyrene-
        divinylbenzene exchange resin in the hydrogen ion form. The liberated hydrogen ions were
        collected in an Erlenmeyer flask, and titrated with
        <stemble-latex content="$0.5000\,\text{M}\,$" />
        <chemical-latex content="NaOH" />
        solution. It was found that
        <number-value :value="vol" unit="\text{mL}" />
        of the standard
        <chemical-latex content="NaOH" />
        solution was required to reach the end-point of the titration. Calculate the concentration
        of the
        <chemical-latex content="Ce^{4+}" />
        in the original
        <stemble-latex content="$50.00\,\text{mL}$" />
        solution.
      </p>

      <p class="mb-1" style="font-weight: bold">
        Concentration of
        <chemical-latex content="Ce^{4+}" />
        in the original
        <stemble-latex content="$50.00\,\text{mL}$" />
        solution:
      </p>

      <calculation-input v-model="inputs.input1" class="mb-3" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';
import CalculationInput from '../inputs/CalculationInput.vue';
import NumberValue from '../NumberValue.vue';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUPEI2210A4Q4',
  components: {
    StembleLatex,
    CalculationInput,
    ChemicalLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    vol() {
      return this.taskState.getValueBySymbol('vol').content;
    },
  },
};
</script>
<style scoped></style>
